import axios from "axios";
// login user
const login=async(userData)=>{
    var data = JSON.stringify(userData);
      var config = {
        method: 'post',
        url: 'https://curatetomorrow.fori.co/admin/login',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };

    const response= await axios(config).then(function (response) {
        if(response.data.msg==="Logged In successfully"){
            localStorage.setItem("user",JSON.stringify(response.data))
            return response.data
        }else{
            throw new Error(response.data.message)
        }
      })
      return response

}
// ...............................logut user
const logout=()=>{
    localStorage.removeItem('user')
}
const authService={
    login,
    logout
}

export default authService;