import {createSlice ,createAsyncThunk} from  "@reduxjs/toolkit";
import authService from "./authServices";


// Get user from local storage ..........
const user=JSON.parse(localStorage.getItem("user"))

const initialState={
    user:user?user:null,
    isError:false,
    isSucess:false,
    isLoading:false,
    message:"",
}


// login user..............................
export const login=createAsyncThunk("auth/login",async (user,thunkApi)=>{
    try{
        return await authService.login(user)
    }catch(error){
        console.log("error ocured in login")
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkApi.rejectWithValue(message);
    }
}
)
// logout.................................................
export const logout=createAsyncThunk("auth/logout",async()=>{
    authService.logout()
})



export const authSlice=createSlice({
    name:"auth",
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading=false
            state.isSucess=false
            state.isError=false
            state.message=""
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(login.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(login.fulfilled,(state,action)=>{
            state.isLoading=false
            state.isSucess=true
            state.user=action.payload

        })
        .addCase(login.rejected,(state,action)=>{
            state.isLoading=false
            state.isError=true
            state.message=action.payload
            state.user=null
        })
        .addCase(logout.fulfilled,(state)=>{
            state.user=null
        })
    }
})

export const {reset}=authSlice.actions;
export default authSlice.reducer;