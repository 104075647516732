import axios from "axios";

// get delegates

// const Url= 'http://localhost:3004/delegate/';
const Url= 'https://curatetomorrow.fori.co/delegate/';

const getDelegates=async(token)=>{

  const config={
      headers:{
          'Authorization':`Bearer ${token}`
      }
  }
  const response=await axios.get(Url,config)
      return response.data.delegates
}
// create delegate

const createDelegate=async(data,token)=>{

    const config={
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type': 'multipart/form-data'
        }
    }
    const response=await axios.post(Url,data,config)
        return response
  }
  // update delegate

const updateDelegate=async(data,token)=>{

    const config={
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type': 'multipart/form-data'
        }
    }
    const response=await axios.patch(`${Url}${data.id}`,data.fdata,config)
    return response
  }
  //   delete attendee
  const deleteDelegate=async(id,token)=>{

    const config={
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }
    const response=await axios.delete(Url+id,config,{withCredentials: true})
        return response
  }
const attendeeService={
 
    getDelegates,
    createDelegate,
    updateDelegate,
    deleteDelegate
}


export default attendeeService;