import { useState,useEffect } from "react";
// import {FaSignInAlt} from "react-icons/fa";
import {useSelector,useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {login,reset} from "../../features/auth/authSlice";
import Spinner from "../../components/Spinner";
import "./login.css"
import {togglebars} from "../../features/bars/bars"




const Login = () => {
  const [formData,setformData]=useState({
    email:"",
    password:"",
  })

  const {email,password}=formData;

  const navigate=useNavigate();
  const dispatch=useDispatch();
  // isSucess put below
  const {user,isLoading,message,isError}=useSelector((state)=>state.auth)

useEffect(()=>{
  dispatch(togglebars())
})


  // ....................................................useEffect....................
  useEffect(()=>{
    if(isError){
      toast.error(message)
    }
    if(user){
      navigate("/delegates")
    }    
     return ()=>dispatch (reset())
  } ,[user,dispatch,message,isError,navigate])


  // ....................................................onChange Function ,called when user change info form..............................
const onChange=(e)=>{
  setformData({...formData,[e.target.name]:e.target.value})
}
  // ....................................................onSubmit function,called when form is submitted...................................
  const onSubmit=(e)=>{
    e.preventDefault();
    const userData ={
      email, password
    }
    // navigate("/users")
if(userData.email&& userData.password){
    dispatch(login(userData))}
  }
if(isLoading===true){
  return <Spinner/>
}
  return (
    <div className="LoginForm">
      <section className="heading">
      <div className="logoLogin">
        <img src={require('../../components/logo.png')} alt="logo" className="Forilogo"/>
      </div>
        <p>Please Login First to get started</p>
      </section>

      <section className="form">
        <form onSubmit={onSubmit}>
          <div className="form-group">
           <input type="email" className="form-control" id="email" name="email" value={email} placeholder="Enter your email" onChange={onChange}/>
          </div>
          <div className="form-group">
           <input type="password" className="form-control" id="password" name="password" value={password} placeholder="Enter a password" onChange={onChange}/>
          </div>
          <div className="form-group">
           <center><button type="submit" className="btn btn-block" onChange={onChange}>Login</button></center>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Login