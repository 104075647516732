import {FaSignInAlt,FaSignOutAlt} from "react-icons/fa";

import {Link,useNavigate} from "react-router-dom";

// import { useEffect } from "react";

import { useSelector,useDispatch } from "react-redux";
import { logout,reset } from "../features/auth/authSlice";

const Header = () => {

  const navigate=useNavigate();
  const dispatch=useDispatch();
  const {user}=useSelector((state)=>state.auth)

  const onlougout=()=>{
    dispatch(logout())
    dispatch(reset())
    navigate("/")
  }




  return (
    <header className="header">
      <div className="logo">
        <img src={require('./logo.png')} alt="logo" className="Forilogo"/>
      </div>
        {user?(
            <button className="btn logout-btn" onClick={onlougout}><FaSignOutAlt/> Logout</button>

        ):(
          <>
            <Link to="/" className="btn "><FaSignInAlt/> Login</Link>
            </>
        )}
    </header>
  )
}

export default Header;