import axios from "axios";

// get Sponsors

// const Url= 'http://localhost:3004/sponsor/'
const Url= 'https://curatetomorrow.fori.co/sponsor/';

const getSponsors=async(token)=>{

  const config={
      headers:{
          'Authorization':`Bearer ${token}`
      }
  }
  const response=await axios.get(Url,config)
      return response.data.sponsors
}
// create Sponsor

const createSponsor=async(data,token)=>{
    // const config={
    //     headers:{
    //         'Authorization':`Bearer ${token}`
    //     }
    // }
    const response=await axios.post(Url,data)
        return response
  }
  // update Sponsor

const updateSponsor=async(data,token)=>{

    const config={
        headers:{
            'Authorization':`Bearer ${token}`,
            'content-type': 'multipart/form-data'
        }
    }
    const response=await axios.patch(`${Url}/${data.id}`,data.fdata,config)
    return response
  }
  //   delete sponsor
  const deleteSponsor=async(id,token)=>{
console.log(id)
    const config={
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }
    const response=await axios.delete(Url+id,config,{withCredentials: true})
        return response
  }
const attendeeService={
 
    getSponsors,
    updateSponsor,
    createSponsor,
    deleteSponsor
}

export default attendeeService;