import React from 'react'
import './Sidebar.css';
import { MdOutlineClose } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import {HiUserGroup} from "react-icons/hi";
import {GiCash} from "react-icons/gi";
import {NavLink } from 'react-router-dom';
import { useState,useEffect } from 'react';

const Sidebar = () => {
    var url=window.location.href
    const [Wurl ,seturl]=useState(url)
    const [screenSize,setScreenSize]=useState(undefined)
    const [activeMenu,setActiveMenu]=useState(true)
    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
      }, [setScreenSize]);
    useEffect(() => {
        if (screenSize <= 900 ) {
          setActiveMenu(false);
        } else{
          setActiveMenu(true);
        }
      }, [setActiveMenu,screenSize,Wurl]);
useEffect(()=>{
    seturl(window.location.href)
},[])

    const handleActiveMenu = () =>{
            setActiveMenu(!activeMenu)
        };
  return (
    <div className='sidebarContainer'>
    <button onClick={handleActiveMenu} className="btnMenu"><AiOutlineMenu /></button>
    {activeMenu && (
        <div className='sidebar'>
        <div className="sidebarWrapper">
            <div className="sidebarMenu">
            <div className="topbarLeft">
            <h2 className='logo'>Admin Pannel</h2>
            <button
                type="button"
                onClick={handleActiveMenu}
                className="cancel-btn">
                <MdOutlineClose />
              </button>
            </div>
            <div className='listitem'>
            <NavLink to='/delegates' className='Link'>
                <HiUserGroup className='sidebarIcon'/>
                Delegates
            </NavLink>
            <NavLink to="/sponsors" className='Link'>
                <GiCash className='sidebarIcon'/>
                Sponsors
            </NavLink>
            </div>
            </div>
        </div>
        </div>
        )
}
</div>

  )
}

export default Sidebar