import {createSlice ,createAsyncThunk} from  "@reduxjs/toolkit";
import { toast } from "react-toastify";
import sponsorsService from "./sponsorsService";
// Get user from local storage ..........

const initialState={
    sponsors:[],
    SisError:false,
    SisSucess:false,
    SisLoading:false,
    Smessage:"" ,
    Sreload:false
}
 

// get sponsorss
export const getSponsors=createAsyncThunk("Sponsors/getAll",async(_,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const response=await sponsorsService.getSponsors(token);
        return response
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})
// create sponsor
export const createSponsor=createAsyncThunk("sponsor/creatNew",async(data,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const response=await sponsorsService.createSponsor(data,token);
        return response
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})
// update sponsor
export const updateSponsor=createAsyncThunk("sponsor/updateOne",async(data,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const responses=await sponsorsService.updateSponsor(data,token);
        return responses.data
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})
// delete sponsor
export const deleteSponsor=createAsyncThunk("sponsor/delete",async(id,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const response=await sponsorsService.deleteSponsor(id,token);
        // getSponsors()
        console.log(response.data)
        return response.data
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message);
    }
})
export const sponsorsSlice=createSlice({
    name:"sponsors",
    initialState,
    reducers:{
        Sreset:(state)=>{
            state.SisLoading=false
            state.SisSucess=false
            state.SisError=false
            state.Smessage=""
        }
    },
    extraReducers: (builder)=>{
        builder
        // getsponsorss
        .addCase(getSponsors.pending,(state)=>{
            state.SisLoading=true
        })
        .addCase(getSponsors.fulfilled,(state,action)=>{
            state.SisLoading=false
            state.SisSucess=true
            state.sponsors=[...action.payload]
        })
        .addCase(getSponsors.rejected,(state,action)=>{
            state.SisLoading=false
            state.SisError=true
            state.Smessage=action.payload
        })
 // create sponsor
 .addCase(createSponsor.pending,(state)=>{
    state.SisLoading=true
})
.addCase(createSponsor.fulfilled,(state,action)=>{
    state.SisLoading=false
    state.SisSucess=true
    if(!action.payload.data.errors && action.payload.status===200 && action.payload.statusText
==="OK"        ){
        toast.success("Sponsor registered sucessfully")
    }else if(action.payload.data.errors.length>0){
        action.payload.data.errors.forEach((e)=>{
        toast.error(e.message)
        })
    }
    // state.sponsors=[...action.payload]
})
.addCase(createSponsor.rejected,(state,action)=>{
    state.SisLoading=false
    state.SisError=true
    state.Smessage=action.payload
})
        // DeleteDelegatess
               .addCase(deleteSponsor.pending,(state)=>{
                state.SisLoading=true
            })
            .addCase(deleteSponsor.fulfilled,(state,action)=>{
                state.SisLoading=false
                state.SisSucess=true
                state.Sreload=!state.Sreload
                if(action.payload.message==="Sponsor deleted successfully" && action.payload.success===true){
                    toast.success("Sponsor deleted successfully")
                }else{
                    state.Smessage=action.payload.message.message
                }
            })
            .addCase(deleteSponsor.rejected,(state,action)=>{
                state.SisLoading=false
                state.SisError=true
                state.Smessage=action.payload
            })
            // updatesponsors
            .addCase(updateSponsor.pending,(state)=>{
                state.SisLoading=true
            })
            .addCase(updateSponsor.fulfilled,(state,action)=>{
                state.SisLoading=false
                state.SisSucess=true
                state.Sreload=!state.Sreload
                if(action.payload.message==="Sponsor updated successfully"){
                   toast.success("information updated")
                }
            })
            .addCase(updateSponsor.rejected,(state,action)=>{
                state.SisLoading=false
                state.SisError=true
                state.Smessage=action.payload
            })
     
   
    }
})

export const {Sreset}=sponsorsSlice.actions;
export default sponsorsSlice.reducer;