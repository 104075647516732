
import { getDelegates,deleteDelegate,updateDelegate,Dreset } from "../../features/users/delegates/delegateSlice";
import './Delegates.css'
import { DataGrid } from '@mui/x-data-grid';
import {useSelector,useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import { useState,useEffect } from "react";
import {togglebars} from "../../features/bars/bars"
import {Link} from "react-router-dom";
import {TiTick} from "react-icons/ti";
import {MdDeleteForever} from "react-icons/md";
import {BiEditAlt,BiShowAlt} from "react-icons/bi"
import Spinner from "../../components/Spinner";
import Tooltip from '@mui/material/Tooltip';
const Delegates = () => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  // ..............................................................useState............
  const {delegates}=useSelector((state)=>state.delegates);
  const[showmodal,setShowmodal]=useState(false); 
  const[showmodalD,setShowmodalD]=useState(false); 
  const [modalValue,setModalValue]=useState([]);
  const {user}=useSelector((state)=>state.auth)
  const {DisLoading,DisError,Dmessage,Dreload}=useSelector((state)=>state.delegates)
  // ..............................................................useEffect....................
  useEffect(()=>{
    dispatch(togglebars())
  })
  useEffect(()=>{
    // dispatch(getDelegates())
    console.log(DisError,Dmessage)

    // console.log(Dmessage)
    if(!user){
      dispatch(Dreset())
      navigate("/")
    }
    if(DisError && Dmessage){
      toast.error(Dmessage)
    }
    return ()=>{dispatch(Dreset())}
  },[user,navigate,dispatch,DisError,Dmessage] )

  useEffect(()=>{
    if(user){
    dispatch(getDelegates())
    console.log("dispatch ki wajah sy chala")
    setShowmodalD(false)}
  },[Dreload,dispatch,user])
  // .........................................................MUI table settings......................
const columns = [
  { field: 'fullName', headerName: 'Name', width: 200 , renderCell:(params)=>{
    return(
        <div className='UserListUSer'>
            <img src={"https://curatetomorrow.fori.co/"+params.row.profileImage} alt="" />
            {params.row.fullName}
        </div>
    )
}},
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'organizationName', headerName: 'Organization', width: 130 },
  { field: 'paymentDone', headerName: 'Payment Status', width: 80 },
  {   field:'approved' , headerName: 'Approve ', with:250 , renderCell:(params)=>{
    return(<>
     <Tooltip title="Set approved Status">
      <button className='tikTackApprove' style={{color:params.row.approved.toString()==="true"?"green":"lightgray"}} onClick={()=>{
         const fdata=new FormData();
         const id=params.row._id;
         if(params.row.approved===true && (DisLoading===false || DisLoading==="")){
        fdata.append("approved", false)
        dispatch(updateDelegate({fdata,id}))
      }else if(params.row.approved===false && (DisLoading===false || DisLoading==="")){
        fdata.append("approved", true)
        dispatch(updateDelegate({fdata,id}))
      }

      }}><TiTick/></button>
      </Tooltip>
      <p>{params.row.approved.toString()}</p>
      </>
    )
} },
{   field:'UpdateWhole' , headerName: 'Information', with:100 , renderCell:(params)=>{
  return(
  <>
     <Tooltip title="Update info">
    <Link to={"/update/"+params.row._id}  className='fullDetailtsBtn'><BiEditAlt/></Link>
    </Tooltip>
    <Tooltip title="See full info">
    <button className='fullDetailtsBtn' onClick={()=>modal(params.row)}><BiShowAlt/></button>
    </Tooltip>
    </>
  )
} },
{   field:'delete' , headerName: 'Delete', renderCell:(params)=>{
  return(
  <>
     <Tooltip title="Click to delete">
    <button onClick={()=>Dmodal(params.row)} style={{backgroundColor:"transparent",border:"none"}}><MdDeleteForever style={{color:"#e12121",backgroundColor:"transparent",fontSize:"1.4rem",marginLeft:"1rem "}}/></button>
    </Tooltip>
    </>
  )
} },
];
// .........................................................modal to see full info..................................
const modal=(obj)=>{
  setShowmodal(true)
  setModalValue(obj)
}
// .........................................................modal for delete warning....................................
const Dmodal=(obj)=>{
setShowmodalD(true)
setModalValue(obj)
}

// ........................................................major return for attendee function...................................
  return (
  <div className='UserListParent'>
   {DisLoading?<Spinner/>:null}
    {delegates? <>
   <div className='UserList'>
       <DataGrid
        rows={delegates}
        columns={columns}
        pageSize={10}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[10]}
        checkboxSelection
         disableSelectionOnClick/>
        </div>
        <Link to="/newAttendee" style={{backgroundColor:"#33285e",color:"white",textDecoration:"none"}} className='fullDetailtsBtn'>Add new Delegate</Link>
        {/* ...............................................................modal for full info of attendeee.............................. */}
        {showmodal?<div className='attendeeModal'>
          <div className='attendeeModal-container'>
          <button onClick={()=>setShowmodal(false)}>Close</button>
          <center><img className='modalProfileImg' src={"https://curatetomorrow.fori.co/"+modalValue.profileImage} alt="profileimg"/></center>

          {modalValue?
          <div className='detailsAttendee' id="detailsAttendee">
            <p><span>fullName</span> :<span> {modalValue.fullName}</span></p>
            <p><span>email</span> :<span> {modalValue.email}</span></p>
            <p><span>organizationName</span> :<span> {modalValue.organizationName}</span></p>
            <p><span>organizationType </span>: <span>{modalValue.organizationType}</span></p>
            <p><span>amountPaid</span> :<span> {modalValue.amountPaid}</span></p>
            <p><span>approved</span> :<span> {modalValue.approved.toString()}</span></p>
            <p><span>participationReason</span> :<span> {modalValue.participationReason}</span></p>
            <p><span>contribute</span> :<span> {modalValue.contribute}</span></p>
            <p><span>participationType </span>: <span> {modalValue.participationType}</span></p>
            <p><span>paymentDone </span>: <span>{modalValue.paymentDone.toString()}</span></p>
            <p><span>paymentEmail </span>: <span>{modalValue.paymentEmail}</span></p>
            <p><span>paymentId </span>: <span>{modalValue.paymentId}</span></p>
            <p><span>paymentMethod </span>: <span>{modalValue.paymentMethod}</span></p>
            <p><span>socialMedia_fb </span>: <span>{modalValue.socialMedia_fb}</span></p>
            <p><span>socialMedia_in </span>: <span> {modalValue.socialMedia_in}</span></p>
            <p><span>socialMedia_ln </span>: <span>{modalValue.socialMedia_ln}</span></p>
            <p><span>socialMedia_tw </span>: <span> {modalValue.socialMedia_tw}</span></p>
            <p><span>createdAt</span> :<span> {modalValue.createdAt}</span></p>
            <p><span>updatedAt</span> :<span> {modalValue.updatedAt}</span></p>
            <p><span>_id </span>: <span>{modalValue._id}</span></p>
          </div>
          :null}</div>
          </div>:null}
          {/* ...........................................................modal for delete warning................. */}
          {showmodalD?<div className='attendeeModalD'>
          <div className='attendeeModal-containerD'>
          <button onClick={()=>setShowmodalD(false)}>Close</button>
          <center className="deleteConfirmation">
            <h1>Are you sure to delete this attendee</h1>
          {modalValue?
            <>
            <div>
            <p><span>fullName</span> :<span> {modalValue.fullName}</span></p>
            <p><span>email</span> :<span> {modalValue.email}</span></p>
            </div>
            <div>
              <button onClick={()=>{dispatch(deleteDelegate(modalValue._id))}}>Yes</button>
              <button onClick={()=>setShowmodalD(false)}>No</button>
            </div>
            </>
          :null}</center></div>
          </div>:null}
    </>:
null
  }
 </div> )
}

export default Delegates;