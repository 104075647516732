import {createSlice ,createAsyncThunk} from  "@reduxjs/toolkit";
import { toast } from "react-toastify"; 
import delegateService from "./delegatesService";
// Get user from local storage ..........

const initialState={
    delegates:[],
    DisError:false,
    DisSucess:false,
    DisLoading:false,
    Dreload:false,
    Dmessage:"" 
}
 

// get delegatess
export const getDelegates=createAsyncThunk("delegates/getAll",async(_,thunkAPI)=>{
    console.log("from get delegates")
    try{
        const token=thunkAPI.getState().auth.user.token;
        const response=await delegateService.getDelegates(token);
        return response
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message);
    }
})
// create delegate
export const createDelegate=createAsyncThunk("delegate/creatNew",async(data,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const response=await delegateService.createDelegate(data,token);
        return response
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})
// update delegate
export const updateDelegate=createAsyncThunk("delegate/updateOne",async(data,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const responses=await delegateService.updateDelegate(data,token);
        return responses.data
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message);
    }
})
// delete delegate
export const deleteDelegate=createAsyncThunk("delegate/delete",async(id,thunkAPI)=>{
    try{
        const token=thunkAPI.getState().auth.user.token;
        const response=await delegateService.deleteDelegate(id,token);
        getDelegates()
        return response.data
    }catch(error){
        const message=(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})
export const delegatesSlice=createSlice({
    name:"delegates",
    initialState,
    reducers:{
        Dreset:(state)=>{
            console.log("Dreset is called")
            state.DisLoading=false
            state.DisSucess=false
            state.DisError=false
            state.Dmessage=""
            state.Dreload=false
        }
    },
    extraReducers: (builder)=>{
        builder
        // getDelegatess
        .addCase(getDelegates.pending,(state)=>{
            state.DisLoading=true
            
        })
        .addCase(getDelegates.fulfilled,(state,action)=>{
            
            state.DisLoading=false
            state.DisSucess=true

            state.delegates=[...action.payload]
        })
        .addCase(getDelegates.rejected,(state,action)=>{
            state.DisLoading=false
            state.DisError=true
            state.Dmessage=action.payload
        })
        // DeleteDelegatess
        .addCase(deleteDelegate.pending,(state)=>{
            state.DisLoading=true
        })
        .addCase(deleteDelegate.fulfilled,(state,action)=>{
            state.DisLoading=false
            state.DisSucess=true
            if(action.payload.message==="Delegate deleted successfully" && action.payload.success===true){
                    state.Dreload=!state.Dreload
                toast.success("Delegate deleted successfully")
            }
        })
        .addCase(deleteDelegate.rejected,(state,action)=>{
            state.DisLoading=false
            state.DisError=true
            state.Dmessage=action.payload
        })
         // create delegate
         .addCase(createDelegate.pending,(state)=>{
            state.DisLoading=true
            state.Dmessage=""
        })
        .addCase(createDelegate.fulfilled,(state,action)=>{
            state.DisLoading=false
            state.DisSucess=true
            if(!action.payload.data.errors && action.payload.status===200 && action.payload.statusText
                ==="OK"        ){
                        toast.success("delegate registered sucessfully")
                        state.Dmessage="delegate registered sucessfully"
                    }
                    else if(action.payload.data.errors.length>0){
                        action.payload.data.errors.forEach((e)=>{
                        toast.error(e.message)
                        })
                    }
        })
        .addCase(createDelegate.rejected,(state,action)=>{
            state.DisLoading=false
            state.DisError=true
            state.Dmessage=action.payload
        })
   
        // updateDelegatess
        .addCase(updateDelegate.pending,(state)=>{
            state.DisLoading=true
        })
        .addCase(updateDelegate.fulfilled,(state,action)=>{
            state.DisLoading=false
            state.DisSucess=true
            state.Dreload=!state.Dreload
            if(action.payload.message==="Delegate updated successfully"){
               toast.success("information updated")
            }
        })
        .addCase(updateDelegate.rejected,(state,action)=>{
            state.DisLoading=false
            state.DisError=true
            state.Dmessage=action.payload
        })
   
    }
})



export const {Dreset}=delegatesSlice.actions;
export default delegatesSlice.reducer;