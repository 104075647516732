import React,{useState} from 'react';
import { useDispatch} from 'react-redux';
import { toast } from 'react-toastify';
import {useSelector} from "react-redux";
import Spinner from "../../../components/Spinner";
import {createSponsor} from "../../../features/users/sponsors/sponsorsSlice";
import "./updateDelegate.css"
import { useNavigate } from 'react-router-dom';
const UpdateAttendee = () => {
  const {SisLoading}=useSelector((state)=>state.sponsors)
const navigate=useNavigate();
    const [data, setdata] = useState({
        amountPaid:'',
        approved:"",
        amount:"",
        email:'',
        fullName:'',
        organizationName:'',
        paymentDone:"",
        paymentEmail:'',
        paymentId:'',
        paymentMethod:'',
        ticketTypenew:'',
        _id:"",
      })
      const ValueUpdater = (e)=>{
        setdata({...data , [e.target.name]:e.target.value})
    }
    
    
        const submit =(e)=>{
          e.preventDefault();
          var reqiuredfield=0;
          const validatefield={
            email:data.email,
            fullName:data.fullName,
            organizationName:data.organizationName,
            ticketTypenew:data.ticketTypenew,

          }
          Object.entries(validatefield).forEach((fieldName) => {
            if(!fieldName[1]){
              console.log(fieldName[0])
              console.log(document.getElementById(`${fieldName[0]}Label`))
              document.getElementById(`${fieldName[0]}Label`).style.color="red"
            }else{
              document.getElementById(`${fieldName[0]}Label`).style.color="black"
              reqiuredfield=reqiuredfield+1
            }
          })
        // const fdata=new FormData();
        // fdata.append("amountPaid",data.amountPaid)
        // fdata.append("amount",data.amount)
        // fdata.append("approved", data.approved)
        // fdata.append("email", data.email)
        // fdata.append("fullName",data.fullName)
        // fdata.append("organizationName",data.organizationName)
        // fdata.append("paymentDone",data.paymentDone)
        // fdata.append("paymentEmail",data.paymentEmail)
        // fdata.append("paymentId",data.paymentId)
        // fdata.append("paymentMethod",data.paymentMethod)
        // fdata.append("ticketTypenew",data.ticketTypenew)
        if(reqiuredfield===Object.keys(validatefield).length){
          dispatch(createSponsor(data)).then((result,err)=>{
            console.log(result,"result",err,"err")
            if(result && !result.payload.data.errors && result.payload.status===200 && result.payload.statusText
              ==="OK"){
            navigate("/sponsors")}
            else if(err){
console.log("error")
            }
          })
        }else{
          toast.error("please fill all required field")
        }
        }
    const dispatch=useDispatch();


  return (
    <div className="RigisterFormParent">
   {SisLoading?<Spinner/>:null}
    <div className='header-register'>
     <h1>Add new Sponsor</h1>
    </div>
    
    <div className="formParent">
    <form className="registrationForm" encType="multipart/form-data">
      <div className="formInputs">
        <div>
        <label htmlFor="email" id="emailLabel">Email Address</label>
        <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="OrganizationsName" id="organizationNameLabel">Organizations Name</label>
        <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="FullName" id="fullNameLabel">Full Name</label>
        <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
        </div>
      </div>     
      <div className="radioDiv">
       
          <div className="radioDiv">
       
      <div className="formOptions">
        <div className="radioDiv">
          <p id="ticketTypenewLabel">Select Sponsor Type </p>

          <div>
          <div>
            <input type="radio" id="Primary" name="ticketTypenew" value="Primary Partner" onChange={ValueUpdater}/>
            <label htmlFor="Primary">Primary ($3000)</label>
          </div>

          <div>
            <input type="radio" id="SilverPartner" name="ticketTypenew" value="Silver Partner" onChange={ValueUpdater}/>
            <label htmlFor="SilverPartner">Silver Partner ($8000)</label>
          </div>

          <div>
            <input type="radio" id="GoldPartner" name="ticketTypenew" value="Gold Partner" onChange={ValueUpdater}/>
            <label htmlFor="GoldPartner">Gold Partner ($15000)</label>
          </div>

          <div>
            <input type="radio" id="PlatinumPartner" name="ticketTypenew" value="Platinum Partner" onChange={ValueUpdater}/>
            <label htmlFor="PlatinumPartner">Platinum Partner ($30000)</label>
          </div>

          <div>
            <input type="radio" id="PoweredBy" name="ticketTypenew" value="Powered By" onChange={ValueUpdater}/>
            <label htmlFor="PoweredBy">Powered By ($60000)</label>
          </div>

          </div>
           <p>Approve Status <small>(optional)</small></p>

        <div>
        <div>
          <input type="radio" id="approvedtrue" name="approved" value="true" onChange={ValueUpdater}/>
          <label htmlFor="approvedYES">Yes</label>
        </div>

        <div>
          <input type="radio" id="approvedfalse" name="approved" value="false" onChange={ValueUpdater}/>
          <label htmlFor="approvedNo">NO</label>
        </div>
      </div>
        </div>
        </div>
      </div>
      <div className="radioDiv">
        <p>Payment Status <small>(optional)</small></p>

        <div>
        <div>
          <input type="radio" id="paymentDonetrue" name="paymentDone" value="true" onChange={ValueUpdater}/>
          <label htmlFor="paymentYes">Yes</label>
        </div>

        <div>
          <input type="radio" id="paymentDonefalse" name="paymentDone" value="false" onChange={ValueUpdater}/>
          <label htmlFor="paymentNo">NO</label>
        </div>
      </div>
      </div>
      <div className="formInputs">
        <div>
        <label htmlFor="paymentemail">Payment Email <small>(optional)</small></label>
        <input type="email" id="paymentemail" name='paymentEmail' value={data.paymentEmail} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentId">payment Id <small>(optional)</small></label>
        <input type="text" id="paymentId" name='paymentId' value={data.paymentId} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentMethod">paymentMethod <small>(optional)</small></label>
        <input type="text" id="paymentMethod" name='paymentMethod' value={data.paymentMethod} onChange={ValueUpdater}/>
        </div>
      </div>
      </div>

      <button type="submit" className="submitBtn" onClick={submit}>Submit</button>
    </form>
    </div>
  </div>
  )
}

export default UpdateAttendee