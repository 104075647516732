import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import {updateSponsor,Sreset} from "../../../features/users/sponsors/sponsorsSlice";
const UpdateSponsor = () => {
    const[turn,setTurn]=useState('')
    const [data, setdata] = useState({
        amountPaid:'',
        approved:"",
        amount:"",
        email:'',
        fullName:'',
        organizationName:'',
        paymentDone:"",
        paymentEmail:'',
        paymentId:'',
        paymentMethod:'',
        _id:"",
      })
      const ValueUpdater = (e)=>{
        setdata({...data , [e.target.name]:e.target.value})
    }
    
    
        const submit =(e)=>{
          console.log("helo from submit")
          e.preventDefault();
        const fdata=new FormData();
        fdata.append("amountPaid",data.amountPaid)
        fdata.append("amount",data.amount)
        fdata.append("approved", data.approved)
        fdata.append("email", data.email)
        fdata.append("fullName",data.fullName)
        fdata.append("organizationName",data.organizationName)
        fdata.append("paymentDone",data.paymentDone)
        fdata.append("paymentEmail",data.paymentEmail)
        fdata.append("paymentId",data.paymentId)
        fdata.append("paymentMethod",data.paymentMethod)
          dispatch(updateSponsor({fdata,id:data._id})).then((result, err)=>{
            if(result.payload.message==="Sponsor updated successfully"){
              navigate("/sponsors")
           }else{
              console.log(err)
          }
          })
        }

    const {sponsors,SisLoading,SisError,SisSucess,Smessage}=useSelector((state)=>state.sponsors);
    const{user}=useSelector((state)=>state.auth)
    const dispatch=useDispatch();
    const navigate=useNavigate();
    useEffect(()=>{

        if(sponsors.length===0){
            navigate("/sponsors")
        }
    })
    useEffect(()=>{

        const href=window.location.pathname;
        const idhref=href.substring(href.lastIndexOf('/') + 1);
        const singleAttendee=sponsors.find((obj,index)=>{
        if(obj._id===idhref){
            return obj
        }
        return null
      })
        if(singleAttendee && sponsors){
              setdata(singleAttendee)
              if(turn===''){
                setTurn('yes')
              }         
        }else{
        }
        },[sponsors,turn])

      useEffect(()=>{
        if(turn==='yes'){
            if(data.paymentDone!==''){
                document.getElementById("paymentDone"+data.paymentDone).checked=true;
                document.getElementById("approved"+data.approved).checked=true;
                setTurn('false')
            }}
        },[turn,data.approved,data.paymentDone])
        useEffect(()=>{

          if(!user){
            dispatch(Sreset())
            navigate("/")
          }
          else if(SisError && Smessage){
            toast.error(Smessage)
          }
          return ()=>dispatch(Sreset())
        },[user,SisError,SisSucess,Smessage,navigate,dispatch] )
  return (
    <div className="RigisterFormParent">
   {SisLoading?<Spinner/>:null}
    <div className='header-register'>
     <h1>Update Sponsor</h1>
    </div>
    
    <div className="formParent">
    <form className="registrationForm" encType="multipart/form-data">
      <div className="formInputs">
        <div>
        <label htmlFor="email">Email Address</label>
        <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="OrganizationsName">Organizations Name</label>
        <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="FullName">Full Name</label>
        <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
        </div>

      </div>   
      <div className="radioDiv">
       
          <div className="radioDiv">
        <p>Approve Status</p>

        <div>
        <div>
          <input type="radio" id="approvedtrue" name="approved" value="true" onChange={ValueUpdater}/>
          <label htmlFor="approvedtrue">Yes</label>
        </div>

        <div>
          <input type="radio" id="approvedfalse" name="approved" value="false" onChange={ValueUpdater}/>
          <label htmlFor="approvedfalse">NO</label>
        </div>
      </div>
      </div>
      <div className="radioDiv">
        <p>Payment Status</p>

        <div>
        <div>
          <input type="radio" id="paymentDonetrue" name="paymentDone" value="true" onChange={ValueUpdater}/>
          <label htmlFor="paymentDonetrue">Yes</label>
        </div>

        <div>
          <input type="radio" id="paymentDonefalse" name="paymentDone" value="false" onChange={ValueUpdater}/>
          <label htmlFor="paymentDonefalse">NO</label>
        </div>
      </div>
      </div>
      <div className="formInputs">
      <div>
        <label htmlFor="amounte">Amount</label>
        <input type="text" id="amount" name='amount' value={data.amount} onChange={ValueUpdater}/>
        </div>        <div>
        <label htmlFor="amountPaid">Amount Paid</label>
        <input type="text" id="amountPaid" name='amountPaid' value={data.amountPaid} onChange={ValueUpdater}/>
        </div>
        <div>
        <label htmlFor="paymentemail">Payment Email </label>
        <input type="email" id="paymentemail" name='paymentEmail' value={data.paymentEmail} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentId">payment Id</label>
        <input type="text" id="paymentId" name='paymentId' value={data.paymentId} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentMethod">paymentMethod</label>
        <input type="text" id="paymentMethod" name='paymentMethod' value={data.paymentMethod} onChange={ValueUpdater}/>
        </div>
      </div>
      </div>

      <button type="submit" className="submitBtn" onClick={submit}>Submit</button>
    </form>
    </div>
  </div>
  )
}

export default UpdateSponsor