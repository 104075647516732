import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/auth/authSlice";
import barsReducer from "../features/bars/bars";
import delegatesReducer from '../features/users/delegates/delegateSlice';
import sponsorsReducer from '../features/users/sponsors/sponsorsSlice';

export const store = configureStore({
  reducer: {

    auth:authReducer,
    bars:barsReducer,
    delegates:delegatesReducer,
    sponsors:sponsorsReducer
  }
});
