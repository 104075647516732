import {createSlice } from  "@reduxjs/toolkit";

export const barSlice=createSlice({
    name:"bar",
    initialState:{
        bars:true
    },
    reducers:{
        togglebars:(state)=>{
        console.log('path',window.location.pathname)
            if(window.location.pathname==="/"){
                state.bars=true
            }else{
                state.bars=false
            }
        }
    }
})
export const {togglebars}=barSlice.actions
export default barSlice.reducer;