import React,{useState} from 'react';
import { useDispatch} from 'react-redux';
import { toast } from 'react-toastify';
import {useSelector} from "react-redux";
import Spinner from "../../components/Spinner";
import { useNavigate } from 'react-router-dom';
import {RiFacebookCircleLine, RiLinkedinLine, RiTwitterLine, RiInstagramLine} from 'react-icons/ri'
import { createDelegate } from '../../features/users/delegates/delegateSlice';
// import "./updateAttendee.css"
const UpdateAttendee = () => {
  const {DisLoading}=useSelector((state)=>state.delegates)
    
const navigate=useNavigate();
    const [data, setdata] = useState({
        amountPaid:'',
        approved:"",
        contribute:'',
        email:'',
        fullName:'',
        organizationName:'',
        organizationType:'',
        participationReason:'',
        participationType:'',
        paymentDone:"",
        paymentEmail:'',
        paymentId:'',
        paymentMethod:'',
        delegate_image:"",
        socialMedia_fb:'',
        socialMedia_ln:'',
        socialMedia_in:'',
        socialMedia_tw:'',
        // delegate_image:'',
        ticketTypenew:'',
        // socialMedia:"waq"
      })
      const ValueUpdater = (e)=>{
        setdata({...data , [e.target.name]:e.target.value})
    }
    
    
        const submit =(e)=>{
          e.preventDefault();
          var reqiuredfield=0;
          const validatefield={
            email:data.email,
            fullName:data.fullName,
            organizationName:data.organizationName,
            organizationType:data.organizationType,
            participationReason:data.participationReason,
            participationType:data.participationType,
            contribute:data.contribute,
            delegate_image:data.delegate_image
          }
          console.log(data.delegate_image)
          Object.entries(validatefield).forEach((fieldName) => {
            if(!fieldName[1]){
              console.log(fieldName[0])
              console.log(document.getElementById(`${fieldName[0]}Label`))
              document.getElementById(`${fieldName[0]}Label`).style.color="red"
            }else{
              document.getElementById(`${fieldName[0]}Label`).style.color="black"
              reqiuredfield=reqiuredfield+1
            }
          })
          console.log(Object.keys(validatefield).length)
          if(reqiuredfield===Object.keys(validatefield).length){
            const fdata=new FormData();
            fdata.append("amountPaid",data.amountPaid)
            fdata.append("approved", data.approved)
            fdata.append("contribute",data.contribute)
            fdata.append("email", data.email)
            fdata.append("fullName",data.fullName)
            fdata.append("organizationName",data.organizationName)
            fdata.append("organizationType",data.organizationType)
            fdata.append("participationType",data.participationType)
            fdata.append("participationReason",data.participationReason)
            fdata.append("paymentDone",data.paymentDone)
            fdata.append("paymentEmail",data.paymentEmail)
            fdata.append("paymentId",data.paymentId)
            fdata.append("paymentMethod",data.paymentMethod)
            fdata.append("delegate_image",data.delegate_image)
            fdata.append("socialMedia_fb",data.socialMedia_fb)
            fdata.append("socialMedia_ln",data.socialMedia_ln)
            fdata.append("socialMedia_in",data.socialMedia_in)
            fdata.append("socialMedia_tw",data.socialMedia_tw)
            fdata.append("ticketTypenew",data.ticketTypenew)
            // fdata.append("socialMedia",data.socialMedia)
              dispatch(createDelegate(fdata)).then((result,err)=>{
                console.log(result,"result",err,"err")
                if(result && !result.payload.data.errors && result.payload.status===200 && result.payload.statusText
                  ==="OK"){
                navigate("/delegates")}
                else if(err){
    console.log("error")
                }
              })
          }else{
            toast.error("please fill all required field")
          }


        }
    const dispatch=useDispatch();

   

  return (
    <div className="RigisterFormParent">
   {DisLoading?<Spinner/>:null}

    <div className='header-register'>
     <h1>Register new Delegate</h1>
    </div>
    
    <div className="formParent">
    <form className="registrationForm" encType="multipart/form-data">
      <div className="formInputs">
        <div>
        <label htmlFor="email" id="emailLabel">Email Address</label>
        <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="OrganizationsName" id="organizationNameLabel">Organizations Name</label>
        <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="FullName" id="fullNameLabel">Full Name</label>
        <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
        </div>
      </div>

      <div className="formOptions">
      <div className="radioDiv">
        <p id="organizationTypeLabel">Organization Type</p>

        <div>
        <div>
          <input type="radio" id="Government" name="organizationType" value="Government" onChange={ValueUpdater}/>
          <label htmlFor="Government">Government</label>
        </div>

        <div>
          <input type="radio" id="Private" name="organizationType" value="Private" onChange={ValueUpdater}/>
          <label htmlFor="Private">Private</label>
        </div>

        <div>
          <input type="radio" id="Public" name="organizationType" value="Public" onChange={ValueUpdater}/>
          <label htmlFor="Public">Public</label>
        </div>

        <div>
          <input type="radio" id="Academia" name="organizationType" value="Academia" onChange={ValueUpdater}/>
          <label htmlFor="Academia">Academia</label>
        </div>

        <div>
          <input type="radio" id="Startup" name="organizationType" value="Startup" onChange={ValueUpdater}/>
          <label htmlFor="Startup">Startup</label>
        </div>

        <div>
          <input type="radio" id="Student" name="organizationType" value="Student" onChange={ValueUpdater} />
          <label htmlFor="Student">Student</label>
        </div>

        <div>
          <input type="radio" id="Other" name="organizationType" value="Other" onChange={ValueUpdater}/>
          <label htmlFor="Other">Other</label>
        </div>
        </div>
      </div>



      <div className="radioDiv">
        <p id="participationTypeLabel">Type Of Participation</p>

        <div>
        <div>
          <input type="radio" id="Invited Delegate" name="participationType" value="Invited Delegate" onChange={ValueUpdater}/>
          <label htmlFor="InvitedDelegate">Invited Delegate</label>
        </div>

        <div>
          <input type="radio" id="Leading Sponsor" name="participationType" value="Leading Sponsor" onChange={ValueUpdater}/>
          <label htmlFor="LeadingSponsor">Leading Sponsor</label>
        </div>

        <div>
          <input type="radio" id="Partner" name="participationType" value="Partner" onChange={ValueUpdater}/>
          <label htmlFor="Partner">Partner</label>
        </div>
          
        <div>      
          <input type="radio" id="Applicant Delegate" name="participationType" value="Applicant Delegate" onChange={ValueUpdater}/>
          <label htmlFor="ApplicantDelegate">Applicant Delegate</label>
        </div>

      </div>
      </div>
      </div>

      <div className="reason">
          <div>
          <p id="participationReasonLabel">Why Would delegate Like To Participate In Curate Tomorrow Event?</p>
          <textarea name="participationReason" id="participationReason" rows="3.5" value={data.participationReason} onChange={ValueUpdater}></textarea>
          </div>
          <div>
          <p id='contributeLabel'>How Would delegate Like To Contribute Towards Curating Tomorrow?</p>
          <textarea name="contribute" id="contribute" rows="3.5" value={data.contribute} onChange={ValueUpdater}></textarea>
          </div>
      </div>

      <div className="imageInput" >
        <div>
        <label htmlFor="image" id="delegate_imageLabel">Please Attach delegate's Latest Photograph</label>
        <input id="imageInput" type="file" accept="image/*" name="delegate_image" onChange={(e)=>setdata({...data,"delegate_image":e.target.files[0]})}/>
        </div>
      </div>

      <div className="socialBox">
        <div className="social">
          <label htmlFor="Facebook"><RiFacebookCircleLine/> Facebook <small>(optional)</small></label>
          <input type="text" name="socialMedia_fb" value={data.socialMedia_fb} onChange={ValueUpdater}/>
        </div>
        <div className="social">
          <label htmlFor="LinkedIn"><RiLinkedinLine/> LinkedIn <small>(optional)</small></label>
          <input type="text" name="socialMedia_ln" value={data.socialMedia_ln} onChange={ValueUpdater}/>
        </div>
        <div className="social">
          <label htmlFor="Instagram"><RiInstagramLine/> Instagram <small>(optional)</small></label>
          <input type="text" name="socialMedia_in" value={data.socialMedia_in} onChange={ValueUpdater}/>
        </div>
        <div className="social">
          <label htmlFor="Twitter"><RiTwitterLine/> Twitter <small>(optional)</small></label>
          <input type="text" name="socialMedia_tw" value={data.socialMedia_tw} onChange={ValueUpdater}/>
        </div>
      </div>

      <div className="radioDiv">
        <p>Select Regitration Type <small>(optional)</small>:</p>
        <div>
        <div>
          <input type="radio" id="CorporateDelegate" name="ticketTypenew" value="Corporate Delegate" onChange={ValueUpdater}/>
          <label htmlFor="CorporateDelegate">Corporate Delegate ($1000)</label>
          </div>

          <div>
          <input type="radio" id="UniversityDelegate" name="ticketTypenew" value="University Delegate" onChange={ValueUpdater}/>
          <label htmlFor="UniversityDelegate">University Delegate ($250)</label>
          </div>

          <div>
          <input type="radio" id="StudentDelegate" name="ticketTypenew" value="Student Delegate" onChange={ValueUpdater}/>
          <label htmlFor="StudentDelegate">Student Delegate ($50)</label>
          </div>

          <div>
          <input type="radio" id="Delegate" name="ticketTypenew" value="Delegate" onChange={ValueUpdater}/>
          <label htmlFor="Delegate">Delegate (Choose Amount)</label>
          </div> 
          <label htmlFor="amountPaid">Amount Paid</label>
          <input type="number" placeholder="Amount" name="amountPaid" value={data.amountPaid} onChange={ValueUpdater}/>

          </div>
      </div>
      <div className="radioDiv">
        <p>Approve Status <small>(optional)</small></p>

        <div>
        <div>
          <input type="radio" id="approveedYes" name="approved" value={true} onChange={ValueUpdater}/>
          <label htmlFor="approvedYES">Yes</label>
        </div>

        <div>
          <input type="radio" id="approvedNo" name="approved" value={false} onChange={ValueUpdater}/>
          <label htmlFor="approvedNo">NO</label>
        </div>
      </div>
      </div>
      <div className="radioDiv">
        <p>Payment Status <small>(optional)</small> </p>

        <div>
        <div>
          <input type="radio" id="paymentYes" name="paymentDone" value={true} onChange={ValueUpdater}/>
          <label htmlFor="paymentYes">Yes</label>
        </div>

        <div>
          <input type="radio" id="paymentNo" name="paymentDone" value={false} onChange={ValueUpdater}/>
          <label htmlFor="paymentNo">NO</label>
        </div>
      </div>
      </div>
      <div className="formInputs">
        <div>
        <label htmlFor="paymentemail">Payment Email <small>(optional)</small></label>
        <input type="email" id="paymentemail" name='paymentEmail' value={data.paymentEmail} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentId">payment Id  <small>(optional)</small></label>
        <input type="text" id="paymentId" name='paymentId' value={data.paymentId} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentMethod">paymentMethod<small>(optional)</small> </label>
        <input type="text" id="paymentMethod" name='paymentMethod' value={data.paymentMethod} onChange={ValueUpdater}/>
        </div>
      </div>
      <button type="submit" className="submitBtn" onClick={submit}>Submit</button>
    </form>
    </div>
  </div>
  )
}

export default UpdateAttendee