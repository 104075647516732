import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import {toast} from "react-toastify";
import {updateDelegate,Dreset} from "../../features/users/delegates/delegateSlice";
import {RiFacebookCircleLine, RiLinkedinLine, RiTwitterLine, RiInstagramLine} from 'react-icons/ri'
const UpdateAttendee = () => {
  const navigate=useNavigate();
      const[turn,setTurn]=useState('')
      const [data, setdata] = useState({
        amountPaid:'',
        approved:"",
        contribute:'',
        email:'',
        fullName:'',
        organizationName:'',
        organizationType:'',
        participationReason:'',
        participationType:'',
        paymentDone:"",
        paymentEmail:'',
        paymentId:'',
        paymentMethod:'',
        profileImage:"",
        socialMedia_fb:'',
        socialMedia_ln:'',
        socialMedia_in:'',
        socialMedia_tw:'',
        _id:"",
        // delegate_image:'',
        ticketTypenew:'',
      })
      const ValueUpdater = (e)=>{
        setdata({...data , [e.target.name]:e.target.value})
    }
    
    
        const submit =(e)=>{
          e.preventDefault();
        const fdata=new FormData();
        fdata.append("amountPaid",data.amountPaid)
        fdata.append("approved", data.approved)
        fdata.append("contribute",data.contribute)
        fdata.append("email", data.email)
        fdata.append("fullName",data.fullName)
        fdata.append("organizationName",data.organizationName)
        fdata.append("organizationType",data.organizationType)
        fdata.append("participationType",data.participationType)
        fdata.append("participationReason",data.participationReason)
        fdata.append("paymentDone",data.paymentDone)
        fdata.append("paymentEmail",data.paymentEmail)
        fdata.append("paymentId",data.paymentId)
        fdata.append("paymentMethod",data.paymentMethod)
        fdata.append("profileImage",data.profileImage)
        fdata.append("socialMedia_fb",data.socialMedia_fb)
        fdata.append("socialMedia_ln",data.socialMedia_ln)
        fdata.append("socialMedia_in",data.socialMedia_in)
        fdata.append("socialMedia_tw",data.socialMedia_tw)
        fdata.append("ticketTypenew",data.ticketTypenew)
          dispatch(updateDelegate({fdata,id:data._id})).then((result, err)=>{
            if(result.payload.message==="Delegate updated successfully"){
              navigate('/delegates')
           }  else if(err){
            console.log("error")}
          })

          

        }

    const {delegates,DisLoading,DisError,DisSucess,Dmessage}=useSelector((state)=>state.delegates);
    const {user}=useSelector((state)=>state.auth)
    const dispatch=useDispatch();
    useEffect(()=>{

        if(delegates.length===0){
            navigate("/delegates")
        }
    })
    useEffect(()=>{

        const href=window.location.pathname;
        const idhref=href.substring(href.lastIndexOf('/') + 1);
        const singleAttendee=delegates.find((obj)=>{
        if(obj._id===idhref){
            return obj
        }
        return null
      })
        if(singleAttendee && delegates){
              setdata(singleAttendee)
              if(turn===''){
                setTurn('yes')
              }         
        }else{
        }
        },[ delegates,turn])
        useEffect(()=>{

          if(!user){
            dispatch(Dreset())
            navigate("/")
          }
          else if(DisError && Dmessage){
            toast.error(Dmessage)
          }
          return ()=>dispatch(Dreset())
        },[user,DisError,DisSucess,Dmessage,navigate,dispatch] )
      useEffect(()=>{
        if(turn==='yes'){
            if(data.organizationType!==''){
                document.getElementById(data.organizationType).checked=true;
                document.getElementById(data.participationType).checked=true;
                document.getElementById("paymentDone"+data.paymentDone).checked=true;
                document.getElementById("approved"+data.approved).checked=true;
                setTurn('false')
            }}
        },[turn,data.organizationType,data.participationType,data.paymentDone,data.approved])

  return (
    <div className="RigisterFormParent">
   {DisLoading?<Spinner/>:null}
    <div className='header-register'>
     <h1>Update Delegate</h1>
    </div>
    
    <div className="formParent">
    <form className="registrationForm" encType="multipart/form-data">
      <div className="formInputs">
        <div>
        <label htmlFor="email">Email Address</label>
        <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="OrganizationsName">Organizations Name</label>
        <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="FullName">Full Name</label>
        <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
        </div>
      </div>

      <div className="formOptions">
      <div className="radioDiv">
        <p>Organization Type</p>

        <div>
        <div>
          <input type="radio" id="Government" name="OrganizationType" value="Government" onChange={ValueUpdater}/>
          <label htmlFor="Government">Government</label>
        </div>

        <div>
          <input type="radio" id="Private" name="OrganizationType" value="Private" onChange={ValueUpdater}/>
          <label htmlFor="Private">Private</label>
        </div>

        <div>
          <input type="radio" id="Public" name="OrganizationType" value="Public" onChange={ValueUpdater}/>
          <label htmlFor="Public">Public</label>
        </div>

        <div>
          <input type="radio" id="Academia" name="OrganizationType" value="Academia" onChange={ValueUpdater}/>
          <label htmlFor="Academia">Academia</label>
        </div>

        <div>
          <input type="radio" id="Startup" name="OrganizationType" value="Startup" onChange={ValueUpdater}/>
          <label htmlFor="Startup">Startup</label>
        </div>

        <div>
          <input type="radio" id="Student" name="OrganizationType" value="Student" onChange={ValueUpdater} />
          <label htmlFor="Student">Student</label>
        </div>

        <div>
          <input type="radio" id="Other" name="OrganizationType" value="Other" onChange={ValueUpdater}/>
          <label htmlFor="Other">Other</label>
        </div>
        </div>
      </div>



      <div className="radioDiv">
        <p>Type Of Participation</p>

        <div>
        <div>
          <input type="radio" id="Invited Delegate" name="ParticipationType" value="InvitedDelegate" onChange={ValueUpdater}/>
          <label htmlFor="Invited Delegate">Invited Delegate</label>
        </div>

        <div>
          <input type="radio" id="Leading Sponsor" name="ParticipationType" value="LeadingSponsor" onChange={ValueUpdater}/>
          <label htmlFor="Leading Sponsor">Leading Sponsor</label>
        </div>

        <div>
          <input type="radio" id="Partner" name="ParticipationType" value="Partner" onChange={ValueUpdater}/>
          <label htmlFor="Partner">Partner</label>
        </div>
          
        <div>      
          <input type="radio" id="Applicant Delegate" name="ParticipationType" value="ApplicantDelegate" onChange={ValueUpdater}/>
          <label htmlFor="Applicant Delegate">Applicant Delegate</label>
        </div>

      </div>
      </div>
      </div>

      <div className="reason">
          <div>
          <p>Why Would You Like To Participate In Curate Tomorrow Event?</p>
          <textarea name="participationReason" id="participationReason" rows="3.5" value={data.participationReason} onChange={ValueUpdater}></textarea>
          </div>
          <div>
          <p>How Would You Like To Contribute Towards Curating Tomorrow?</p>
          <textarea name="contribute" id="contribute" rows="3.5" value={data.contribute} onChange={ValueUpdater}></textarea>
          </div>
      </div>

      {/* <div className="imageInput">
        <div>
        <label htmlFor="image">Please Attach Your Latest Photograph</label>
        <input type="file" id="image" accept="image/*" name="profileImage" onChange={(e)=>setdata({...data,["profileImage"]:e.target.files[0]})}/>
        </div>
      </div> */}

      <div className="socialBox">
        <div className="social">
          <label htmlFor="Facebook"><RiFacebookCircleLine/> Facebook</label>
          <input type="text" name="socialMedia_fb" value={data.socialMedia_fb} onChange={ValueUpdater}/>
        </div>
        <div className="social">
          <label htmlFor="LinkedIn"><RiLinkedinLine/> LinkedIn</label>
          <input type="text" name="socialMedia_ln" value={data.socialMedia_ln} onChange={ValueUpdater}/>
        </div>
        <div className="social">
          <label htmlFor="Instagram"><RiInstagramLine/> Instagram</label>
          <input type="text" name="socialMedia_in" value={data.socialMedia_in} onChange={ValueUpdater}/>
        </div>
        <div className="social">
          <label htmlFor="Twitter"><RiTwitterLine/> Twitter</label>
          <input type="text" name="socialMedia_tw" value={data.socialMedia_tw} onChange={ValueUpdater}/>
        </div>
      </div>

      <div className="radioDiv">
        <p>Select Regitration Type:</p>
        <div>
        <div>
          <input type="radio" id="CorporateDelegate" name="ticketTypenew" value="CorporateDelegate" onChange={ValueUpdater}/>
          <label htmlFor="CorporateDelegate">Corporate Delegate ($1000)</label>
          </div>

          <div>
          <input type="radio" id="UniversityDelegate" name="ticketTypenew" value="UniversityDelegate" onChange={ValueUpdater}/>
          <label htmlFor="UniversityDelegate">University Delegate ($250)</label>
          </div>

          <div>
          <input type="radio" id="StudentDelegate" name="ticketTypenew" value="StudentDelegate" onChange={ValueUpdater}/>
          <label htmlFor="StudentDelegate">Student Delegate ($50)</label>
          </div>

          <div>
          <input type="radio" id="Delegate" name="ticketTypenew" value="Delegate" onChange={ValueUpdater}/>
          <label htmlFor="Delegate">Delegate (Choose Amount)</label>
          </div>
          <label htmlFor="amountPaid">Amount Paid</label>
          <input type="number" placeholder="Amount" name="amountPaid" value={data.amountPaid} onChange={ValueUpdater}/>

          </div>
          <div className="radioDiv">
        <p>Approve Status</p>

        <div>
        <div>
          <input type="radio" id="approvedtrue" name="approved" value="true" onChange={ValueUpdater}/>
          <label htmlFor="approvedtrue">Yes</label>
        </div>

        <div>
          <input type="radio" id="approvedfalse" name="approved" value="false" onChange={ValueUpdater}/>
          <label htmlFor="approvedfalse">NO</label>
        </div>
      </div>
      </div>
      <div className="radioDiv">
        <p>Payment Status</p>

        <div>
        <div>
          <input type="radio" id="paymentDonetrue" name="paymentDone" value="true" onChange={ValueUpdater}/>
          <label htmlFor="paymentDonetrue">Yes</label>
        </div>

        <div>
          <input type="radio" id="paymentDonefalse" name="paymentDone" value="false" onChange={ValueUpdater}/>
          <label htmlFor="paymentDonefalse">NO</label>
        </div>
      </div>
      </div>
      <div className="formInputs">
        <div>
        <label htmlFor="paymentemail">Payment Email </label>
        <input type="email" id="paymentemail" name='paymentEmail' value={data.paymentEmail} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentId">payment Id</label>
        <input type="text" id="paymentId" name='paymentId' value={data.paymentId} onChange={ValueUpdater}/>
        </div>

        <div>
        <label htmlFor="paymentMethod">paymentMethod</label>
        <input type="text" id="paymentMethod" name='paymentMethod' value={data.paymentMethod} onChange={ValueUpdater}/>
        </div>
      </div>
      </div>

      <button type="submit" className="submitBtn" onClick={submit}>Submit</button>
    </form>
    </div>
  </div>
  )
}

export default UpdateAttendee