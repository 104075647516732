
import './App.css';
import Delegates from './pages/delegates/Delegates';
import Sponsors from './pages/sponsors/Sponsors';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Header from './components/header';
import SideBar from './components/Sidebar/Sidebar';
import {BrowserRouter as Router,Routes,Route}from "react-router-dom";
import Login from "./pages/Login/Login"
import { useEffect} from 'react';
import {useSelector,useDispatch} from "react-redux";
import {togglebars} from "./features/bars/bars";
import UpdateDelegate from "./pages/updateDelegate/updateDelegate";
import UpdateSponsor from "./pages/sponsors/updateSponsor/updateSponsor";
import NewAttendee from "./pages/newAttendee/newAttendee";
import NewSponsor from "./pages/sponsors/newSponsor/newSponsor";



function App() {
const barsController=useSelector((state)=>state.bars.bars);
const dispatch=useDispatch()
useEffect(()=>{
    dispatch(togglebars())
})
  return (
    <div className="App">
      <Router>
        {barsController?null:<Header/>}
        <div className='container'>
        {barsController?null:<SideBar/>}
        <div className='pagesContainer'>
      <Routes>
              <Route path="/newAttendee" element={<NewAttendee/>}/>
              <Route path="/newSponsor" element={<NewSponsor/>}/>
              <Route path="/" element={<Login/>}/>
              <Route path="/delegates" element={<Delegates/>}/>
              <Route path="/sponsors" element={<Sponsors/>}/>
              <Route path="/update/:id" element={<UpdateDelegate/>}/>
              <Route path="/updatesponsor/:id" element={<UpdateSponsor/>}/>
          </Routes>
          </div>
        </div>
    </Router>
    <ToastContainer/>
  </div>
  );
}

export default App;
